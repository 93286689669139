import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Line, ComposedChart, Brush } from 'recharts';
import ChartCard from '../../components/dashboard/ChartCard';
import { convertNotation, convertToUsd } from '../../utils/StringUtil';
import CustomAmountTooltip from './components/CustomTooltip';

const Investment = ({ currency: parentCurrency, investeeCompanyList, deploymentList, inrAgainstUsd }) => {

  const formatDeploymentList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        totalCommitment: convertToUsd(item.totalCommitment, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedDeploymentList = formatDeploymentList(deploymentList);

  return (
    <div className="container-fluid py-4">
      <div className="row">
      <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard title="Number of Investee Companies"
            chartInfo={[
              { name: "No of Investee Companies", color: "#C3B1E1" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={investeeCompanyList}>
                <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                <YAxis
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  tick={{ fontSize: 12, fontWeight: 'bold' }} />
                <Tooltip />
                <Bar dataKey="cumulativeClosedSchemeCount" fill="#C3B1E1" name='Cumulative Closed Scheme Count' />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
   </div>
   <div className='row'>
        <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard
            title="Number of Deployments"
            chartInfo={[
              { name: "Total Fund Received", color: "#DDA0DD" },
              { name: "Closed Schemes", color: "#33006F" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={formattedDeploymentList}>
                <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                <YAxis yAxisId="left"
                  label={{ value: 'Total Fund Received', angle: -90, fontWeight: 'bold', position: 'outsideCenter', fill: "#DDA0DD", dx: -25 }}
                  stroke="#DDA0DD"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  tick={{ fontSize: 12, fontWeight: 'bold' }}
                />
                {/* Right Y-Axis for Count */}
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    fontWeight: 'bold',
                    value: 'MOM Closed Scheme',
                    angle: -90,
                    position: 'outsideRight',
                    fill: "#33006F",
                    dx: 25,
                  }}
                  stroke="#33006F"
                  tickFormatter={(value) => convertNotation(value, parentCurrency, inrAgainstUsd)}
                  tick={{ fontSize: 12, fontWeight: 'bold' }}
                />
                  <Tooltip 
                   content={ 
                   <CustomAmountTooltip
                    parentCurrency={parentCurrency}
                  />
                  }
                />
                <Bar yAxisId="left" dataKey="totalCommitment" barSize={40} fill="#DDA0DD" name="MOM Total Fund Received" />
                <Line yAxisId="right" type="monotone" dataKey="totalClosedScheme" fill="#33006F" stroke="#33006F" name='MOM Closed Scheme' strokeWidth={1} dot={{ r: 2 }} />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

export default Investment;