import { Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import ChartCard from '../../components/dashboard/ChartCard';
import { convertNotation, convertToUsd } from '../../utils/StringUtil';
import CustomAmountTooltip from './components/CustomTooltip';

const Aum = ({ currency: parentCurrency, aumCommittedList, aumInvestedList, inrAgainstUsd }) => {

  const formatAumCommittedList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        cumulativeMcaCommitment: convertToUsd(item.cumulativeMcaCommitment, inrAgainstUsd),
        momMcaCommitment: convertToUsd(item.momMcaCommitment, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedAumCommittedList = formatAumCommittedList(aumCommittedList);

  const formatAumInvestedList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        cumulativeInvestment: convertToUsd(item.cumulativeInvestment, inrAgainstUsd),
        momInvestment: convertToUsd(item.momInvestment, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedAumInvestedList = formatAumInvestedList(aumInvestedList);

  return (
    <div className="container-fluid py-4">
      <div className="row px-4">
        <div className="col-md-8" style={{ width: "80%" }}>
          <ChartCard
            title="AUM Committed"
            chartInfo={[
              { name: "Total MCA Commitments", color: "#dda0dd" },
              { name: "MOM MCA Commitments", color: "#602f6b" },
            ]}
          >
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={formattedAumCommittedList}>
                  <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: "bold" }} />
                  <YAxis
                    yAxisId="left"
                    label={{
                      value: "Cumulative Commitment",
                      angle: -90,
                      fontWeight: "bold",
                      position: "outsideCenter",
                      fill: "#dda0dd",
                      dx: -25,
                      fontSize: 15,
                    }}
                    stroke="#dda0dd"
                    tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                    fontSize={13}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    label={{
                      value: "MOM Commitment",
                      fontWeight: "bold",
                      angle: -90,
                      position: "outsideRight",
                      fill: "#602f6b",
                      dx: 15,
                      fontSize: 15,
                    }}
                    stroke="#602f6b"
                    tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                    fontSize={13}
                  />
                  <Tooltip content={<CustomAmountTooltip parentCurrency={parentCurrency} />} />
                  <Bar
                    yAxisId="left"
                    dataKey="cumulativeMcaCommitment"
                    barSize={40}
                    fill="#dda0dd"
                    name="Cumulative Commitment"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="momMcaCommitment"
                    fill="#602f6b"
                    stroke="#8e4585"
                    name="MOM Commitment"
                    strokeWidth={1}
                    dot={{ r: 2 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
      <div className="row px-4">
        <div className="col-md-8" style={{ width: "80%" }}>
          <ChartCard
            title="AUM Invested"
            chartInfo={[
              { name: "Total Fund Received", color: "#cb99c9" },
              { name: "MOM Fund Received", color: "#8e4585" },
            ]}
          >
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={formattedAumInvestedList}>
                  <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                  <YAxis
                    yAxisId="left"
                    label={{
                      value: 'Cumulative Investment',
                      angle: -90,
                      fontWeight: 'bold',
                      position: 'outsideCenter',
                      fill: "#cb99c9",
                      dx: -29,
                      fontSize: 15,
                    }}
                    stroke="#cb99c9"
                    tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                    fontSize={13}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    label={{
                      value: 'MOM Investment',
                      fontWeight: 'bold',
                      angle: -90,
                      position: 'outsideRight',
                      fill: "#8e4585",
                      dx: 29,
                      fontSize: 15,
                    }}
                    stroke="#8e4585"
                    tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                    fontSize={13}
                  />
                  <Tooltip content={<CustomAmountTooltip parentCurrency={parentCurrency} />} />
                  <Bar
                    yAxisId="left"
                    dataKey="cumulativeInvestment"
                    barSize={40}
                    fill="#cb99c9"
                    name="Cumulative Investments"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="momInvestment"
                    fill="#8e4585"
                    stroke="#8e4585"
                    name='MOM Investments'
                    strokeWidth={1}
                    dot={{ r: 2 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

export default Aum;
