export const TableHeadButton = ({
  label,
  activeUp,
  activeDown,
  leftAlign,
  hideControls,
  align,
  onClick // Add the onClick prop
}) => {
  const iconUpClasses = `bi bi-caret-up-fill ${activeUp ? 'text-primary' : 'text-light'}`;
  const iconDownClasses = `bi bi-caret-down-fill ${activeDown ? 'text-primary' : 'text-light'}`;

  return (
    <button
      style={{ cursor: 'pointer', background: 'none', border: 'none' }} // Ensure the button looks like a table header
      className={`d-flex gap-2 align-items-center text-muted px-0 ${ 
        leftAlign ? "ms-auto" : align === "center" ? "mx-auto" : "" 
      }`}
      onClick={onClick} // Handle clicks
    >
      <p className="p-0 m-0 text-dark">{label}</p>
      <div className={`d-flex flex-column ${hideControls ? 'd-none' : ''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12"  fill="currentColor" className={iconUpClasses} viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" fill="currentColor" className={iconDownClasses} viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
        </svg>
      </div>
    </button>
  );
};
