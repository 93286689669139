import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Switch, FormControlLabel, Box, Divider, Tooltip, Skeleton } from '@mui/material';
import { styled } from "@mui/material/styles";
import Layout from '../../components/layout/Layout';
import Home from './home';
import Investor from './investor';
import Revenue from './revenue';
import Investment from './investment';
import Aum from './aum';
import { apiGetDshbordCardData, apiGetDshbordGraphData } from '../../utils/api/api';
import { useSnackbar } from 'notistack';
import { SNACKBAR_ERROR } from '../../utils/constants/constants';
import { convertToUsd } from '../../utils/StringUtil';

const Dashboard = () => {

    const [index, setIndex] = useState(0);
    const [currency, setCurrency] = useState('INR');
    const [cardData, setCardData] = useState({});
    const [numInvList, setNumInvList] = useState([]);
    const [activeInvList, setActiveInvList] = useState([]);
    const [uniqueInvList, setUniqueInvList] = useState([]);
    const [revenueList, setRevenueList] = useState([]);
    const [investeeCompanyList, setInvesteeCompanyList] = useState([]);
    const [deploymentList, setDeployementList] = useState([]);
    const [aumCommittedList, setAumCommittedList] = useState([]);
    const [aumInvestedList, setAumInvestedList] = useState([]);
    const [inrAgainstUsd, setInrAgainstUsd] = useState(0.00);
    const [loading, setLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event, newValue) => {
        setIndex(newValue);
    };

    const handleCurrencyChange = (event) => {
        setCurrency((prev) => (prev === "USD" ? "INR" : "USD"));
    };

    const getDashboardCardData = () => {
        apiGetDshbordCardData()
            .then((res) => {
                setCardData(res.dashboardData);
                setInrAgainstUsd(res.inrAgainstUsd);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    };

    const [yearFilter, setYearFilter] = useState('currentYear');

    const handleYearFilterChange = (event) => {
        const newFilter = yearFilter === 'currentYear' ? 'beginning' : 'currentYear';
        setYearFilter(newFilter);
    };

    const getDashboardGraphData = (filter) => {
        setLoading(true);
        apiGetDshbordGraphData({ yearFilter: filter })
            .then((res) => {
                setNumInvList(res.numInvList);
                setActiveInvList(res.activeInvList);
                setUniqueInvList(res.uniqueInvList);
                setRevenueList(res.revenueList);
                setInvesteeCompanyList(res.closedSchemeList);
                setDeployementList(res.deploymentList);
                setAumCommittedList(res.aumCommittedList);
                setAumInvestedList(res.aumInvestedList);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        // Fetch card data only on component mount
        getDashboardCardData();
    }, []); // Empty dependency array ensures this runs only once
    
    useEffect(() => {
        // Fetch graph data when yearFilter changes
        getDashboardGraphData(yearFilter);
    }, [yearFilter]);
    

    return (
        <Layout title="Dashboard">
            <Box paddingTop="10px" display="flex" alignItems="center" justifyContent="flex-end">
                <Tabs value={index} onChange={handleChange} indicatorColor="primary" textColor="primary">
                    <Tab label="HOME" />
                    <Tab label="INVESTOR" />
                    <Tab label="REVENUE" />
                    <Tab label="INVESTMENT" />
                    <Tab label="AUM" />
                </Tabs>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <FormControlLabel
                        control={
                            <Tooltip title={currency === 'INR' ? 'Switch to USD' : 'Switch to INR'}>
                                {/* created custom switch for currency */}
                                <div
                                    onClick={handleCurrencyChange}
                                    role="switch"
                                    aria-checked={currency === 'USD'}
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        width: '98px',
                                        height: '34px',
                                        borderRadius: '20px',
                                        backgroundColor: currency === 'USD' ? '#772396' : '#AD7BC0',
                                        padding: '4px',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                >
                                    {/* added toggle end for the switch */}
                                    <div
                                        style={{
                                            width: '26px',
                                            height: '26px',
                                            backgroundColor: '#FBF8FC',
                                            borderRadius: '50%',
                                            transition: 'transform 0.3s ease',
                                            transform: currency === 'USD' ? 'translateX(65px)' : 'translateX(2px)',
                                        }}
                                    />

                                    {/* Single text span */}
                                    <span
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            textAlign: 'center',
                                            fontSize: '18px',
                                            color: '#FFFFFF',
                                            fontWeight: 'bold',
                                            pointerEvents: 'none',
                                            lineHeight: '38px',
                                            transform: currency === 'USD' ? 'translateX(-12px)' : 'translateX(-1px)',
                                        }}
                                    >
                                        {currency === 'USD' ? 'USD' : 'INR'}
                                    </span>
                                </div>
                            </Tooltip>
                        }
                        label=""
                        style={{ marginLeft: 'auto' }}
                    />

                    <FormControlLabel
                        control={
                            <Tooltip title={yearFilter === 'currentYear' ? 'Show From Beginning Data' : 'Show Current Year Data'}>
                                {/* created custom switch for year filter */}
                                <div
                                    onClick={handleYearFilterChange}
                                    role="switch"
                                    aria-checked={yearFilter === 'beginning'}
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        width: '185px',
                                        height: '34px',
                                        borderRadius: '20px',
                                        backgroundColor: yearFilter === 'beginning' ? '#772396' : '#AD7BC0',
                                        padding: '4px',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                >
                                    {/* added toggle end for the switch */}
                                    <div
                                        style={{
                                            width: '26px',
                                            height: '26px',
                                            backgroundColor: '#FBF8FC',
                                            borderRadius: '50%',
                                            transition: 'transform 0.3s ease',
                                            transform: yearFilter === 'beginning' ? 'translateX(150px)' : 'translateX(2px)',
                                        }}
                                    />

                                    {/* Single text span */}
                                    <span
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            textAlign: 'center',
                                            fontSize: '18px',
                                            color: '#FFFFFF',
                                            fontWeight: 'bold',
                                            pointerEvents: 'none',
                                            lineHeight: '38px',
                                            transform: yearFilter === 'beginning' ? 'translateX(-12px)' : 'translateX(-1px)',
                                        }}
                                    >
                                        {yearFilter === 'currentYear' ? 'Current Year' : 'From Beginning'}
                                    </span>
                                </div>
                            </Tooltip>
                        }
                        label=""
                        style={{ marginLeft: 'auto' }}
                    />
                </div>
            </Box>


            <Divider style={{ height: '1px', backgroundColor: '#000' }} />
            {
                loading ? (
                    <div className="container-fluid py-4">
                    <div className="row">
                      {/* Loop for creating skeleton cards */}
                      {Array.from({ length: 3 }).map((_, index) => (
                        <div className="col-md-6 mb-4" key={index}>
                          <div
                            style={{
                              backgroundColor: '#fff',
                              borderRadius: '8px',
                              padding: '16px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                          >
                            {/* Heading Skeleton */}
                            <div
                              style={{
                                backgroundColor: '#e0e0e0',
                                height: '24px',
                                width: '60%',
                                borderRadius: '4px',
                                marginBottom: '16px',
                              }}
                            ></div>
              
                            {/* Graph Placeholder */}
                            <div
                              style={{
                                backgroundColor: '#f0f0f0',
                                height: '300px',
                                width: '100%',
                                borderRadius: '8px',
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                    <div>
                        {index === 0 &&
                            <Home
                                currency={currency}
                                data={cardData}
                                inrAgainstUsd={inrAgainstUsd}
                            />}
                        {index === 1 &&
                            <Investor
                                currency={currency}
                                numInvList={numInvList}
                                uniqueInvList={uniqueInvList}
                                activeInvList={activeInvList}
                                inrAgainstUsd={inrAgainstUsd}
                                yearFilter={yearFilter}
                            />}
                        {index === 2 &&
                            <Revenue
                                currency={currency}
                                revenueList={revenueList}
                                inrAgainstUsd={inrAgainstUsd}
                                yearFilter={yearFilter}
                            />

                        }
                        {index === 3 &&
                            <Investment
                                currency={currency}
                                investeeCompanyList={investeeCompanyList}
                                deploymentList={deploymentList}
                                inrAgainstUsd={inrAgainstUsd}
                                yearFilter={yearFilter}
                            />}
                        {index === 4 &&
                            <Aum
                                currency={currency}
                                aumCommittedList={aumCommittedList}
                                aumInvestedList={aumInvestedList}
                                inrAgainstUsd={inrAgainstUsd}
                                yearFilter={yearFilter}
                            />}
                    </div>
                )
            }


        </Layout>
    );
};

export default Dashboard;
