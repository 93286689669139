import { STATUS_SUCCESS } from "../constants/constants";
import { getFile, httpFileUpload, httpGet, httpMultiFileUpload, httpPost, httpPut } from "./AxiosHelper";
import "./AxiosInterceptor";

//POST REQUESTS

export const authLogin = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/auth', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const verifyOtp = (reqBody) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: "/verify-otp", body: reqBody })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          resolve(res);
        } else {
          reject(res.description);
        }
      })
      .catch((err) => { return reject(err) });
  });
};

export const saveOpportunity = (body) => {

  return new Promise((resolve, reject) => {

    httpPost({ path: '/admin/save-opportunity', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const uploadImage = (file) => {

  return new Promise((resolve, reject) => {

    const form = new FormData();
    form.append('file', file)

    httpFileUpload({ path: '/upload-image', file: form })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const saveSip = (body) => {

  return new Promise((resolve, reject) => {

    httpPost({ path: '/admin/save-sip', body: body })
      .then((res) => {


        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const uploadFile = (file) => {

  return new Promise((resolve, reject) => {

    const form = new FormData();
    form.append('file', file)

    httpFileUpload({ path: '/upload-document', file: form })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })

}

export const apiUploadUnitAllotmentFiles = (utRid, type, invOppRid, files) => {
  return new Promise((resolve, reject) => {
      if (!utRid || !invOppRid) {
          return reject('Required information is missing');
      }

      const form = new FormData();
      form.append('file', files);

      httpFileUpload({ path: '/upload-document', file: form })
          .then((uploadRes) => {
              if (uploadRes.status === STATUS_SUCCESS && uploadRes.data) {
                  const requestBody = {
                      utRid: utRid,
                      userType: type === 'seller' ? 1 : 2,
                      invOppFiles: uploadRes.data,
                      ...(type === 'seller' 
                          ? { sellIoRid: invOppRid }
                          : { buyIoRid: invOppRid })
                  };

                  return httpPut({
                      path: '/admin/unit-transfer/upload-unit-allotment-files',
                      body: requestBody
                  });
              }
              return reject(uploadRes.description);
          })
          .then((res) => {
              if (res.status === STATUS_SUCCESS) {
        
                  return resolve({
                      message: res.description,
                      data: res.data
                  });
              }
              return reject(res.description);
          })
          .catch((e) => reject(e));
  });
};

export const uploadMultipleFile = (files) => {
  return new Promise((resolve, reject) => {
    const form = new FormData();

    Array.from(files).forEach((file) => {
      form.append('files', file);
    });

      httpMultiFileUpload({
          path: '/upload-multiple-document',
          files: form,
      })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
}
export const savePortfolioCompany = (body) => {

  return new Promise((resolve, reject) => {

    httpPost({ path: '/admin/save-portfolio-company', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })

}

export const saveCompany = (body) => {

  return new Promise((resolve, reject) => {

    httpPost({ path: '/admin/save-company', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const savePortfolioInvestor = (body) => {

  return new Promise((resolve, reject) => {

    httpPost({ path: '/admin/save-portfolio-investors', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const editPortfolioCompany = (body) => {

  return new Promise((resolve, reject) => {

    httpPut({ path: '/admin/save-portfolio-company', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const editPortfolioInvestor = (body) => {

  return new Promise((resolve, reject) => {

    httpPut({ path: '/admin/save-portfolio-investors', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const opportunityEdit = (body) => {

  return new Promise((resolve, reject) => {

    httpPut({ path: '/admin/save-opportunity', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const sipEdit = (body) => {

  return new Promise((resolve, reject) => {

    httpPut({ path: '/admin/save-sip', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const investorEdit = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/save-investor', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const userEdit = (body) => {

  return new Promise((resolve, reject) => {

    httpPut({ path: '/admin/save-user', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const companyEdit = (body) => {

  return new Promise((resolve, reject) => {

    httpPut({ path: '/admin/save-company', body: body })
      .then((res) => {

        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}


//GET REQUESTS

export const getOpportunity = (params) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/opportunity', param: params })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getSip = () => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/sip-list' })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })

}

export const getInvestors = (reqParams) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/investor-list', param: reqParams })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getUsers = () => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/user-list' })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getCompanies = (params) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/get-company-list', param: params })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getPortfolioCompanies = (params) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/portfolio-companies', param: params })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getPortfolioInvestors = () => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/portfolio-investors' })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}


export const getDataDictionaryValues = () => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/list-data-dictionaries' })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getCoInvestors = () => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/get-co-investors' })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getOppCoInvestors = (params) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/opp-co-investors', param: { coInvList: params } })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getQueries = (params) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/get-queries', param: params })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getTransactions = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/transactions', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getInvestments = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/investments', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiMca = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/mca', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiNomination = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/nomination', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}



export const apiCreateInvestor = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/save-investor', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiUpdateInvestor = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/save-investor', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeleteInvestor = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/delete-investor', body: reqParams })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getMasterClass = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/class-master', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getDematMasterTable = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/opp-class-demat-table', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiCreateMasterClass = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/class-master', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiUpdateMasterClass = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/class-master', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiInvestorOpportunity = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/investor-opportunity', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiInvestorFeeGenerated = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/opportunity/investors/summary', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiWallet = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/wallet', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveInvestorOpportunity = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/investor-opportunity', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiUpdateInvestorOpportunity = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/investor-opportunity', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiFunds = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/funds', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiSaveFunds = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/fund', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiUpdateFunds = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/investor-opportunity/funds', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiUnitAllotment = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/unit-allotment', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiFundTransferred = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/fund-transferred', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiUpdateFundTransferred = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/fund-transferred', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeleteFund = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/fund', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiCompanyUpdates = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/company-update', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiCompanyUpdateDetails = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/company-update/' + params.cuRid })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiSaveCompanyUpdate = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/company-update', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiUpdateCompanyUpdate = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/company-update', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiTags = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/tags', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiFundReceived = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/fund-received', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSchemeDetails = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/opportunity/' + params.oppRid })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveValuationUpdate = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/valuation-update', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiValuationUpdate = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/valuation-update', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiUpdateValuation = (params) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/valuation-update/' + params.vuRid })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSchemeDescription = (reqBody) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/opportunity/description', body: reqBody })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiPartnerPayment = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/partner-payment', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiSavePartnerPayment = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/partner-payment', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiDeletePartnerPayment = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/partner-payment', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiTransactionFiles = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/opportunity/transaction-files', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeleteScheme = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/opportunity/delete', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSchemeCancel = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/opportunity/cancel', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSchemeSummary = () => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/opportunity/summary' })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveUnitTransfer = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/unit-transfer', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiUnitTransfer = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/unit-transfer', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiUnitAllotmentFile = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/unit-allotment-file', param: params })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSchemeLaunch = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/scheme-launch', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiGetSchemeLaunch = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/scheme-launch', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiInvestorOnboarding = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/investor-on-boarding', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiInvestorOnboardingFile = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/investor-on-boarding-file', param: params })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiGetSilentLoginToken = (userRid) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/get-silent-login-token', param: { userRid } })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveMCA = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/mca', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
export const apiDeleteMCA = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/delete-mca', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeleteNomination = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/nomination/delete', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveNomination = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/nomination', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiEnableInvestorLogin = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/enable-investor-login', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiEnableResendOtp = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/resend-otp', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const getPartners = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/partner-list', param: reqParams })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}


export const apiCreatePartner = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/save-partner', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiUpdatePartner = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/save-partner', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeletePartner = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/delete-partner', body: reqParams })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiEnablePartnerLogin = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/enable-partner-login', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiChangePartnerPassword = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/change-password', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const partnerAuthLogin = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/partner/auth', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

// export const apiDraftInvOppList = (reqParams) => {
//   return new Promise((resolve,reject) => {
//     httpGet({path: '/admin/draft-investments', param: reqParams})
//     .then((res) => {
//       if (res.status === STATUS_SUCCESS && res.data) {
//         return resolve(res.data)
//       }
//       return reject(res.description)
//     })
//     .catch((e) => {
//       return reject(e)
//     })
//   })
// }

export const apiOfflinePaymentList = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/offline-payments', param: reqParams })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiRejectDraftInvestment = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: `/admin/reject-draft-investment/${body?.diRid}` })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiApproveDraftInvestment = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: `/admin/approve-draft-investment`, body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiFeeGenerateReport = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/opportunity/summary/download', param: params })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDetailedFeeGenerateReport = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/opportunity/partner-payments/download', param: params })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSearchCKYC = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: `/admin/search-ckyc`, body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiInvestorDetails = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/investor/' + params.invRid })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiRecordDematerialization = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: `/admin/opportunity/${body?.oppRid}/record-class-dematerialization`, body: body?.dematClassList })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiUpdateDematerialization = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: `/admin/update-dematerialization`, body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiClassDematerialization = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: `/admin/opportunity/${params?.oppRid}/class-dematerialization` })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiInvestorListDownload = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/investor-list/download', param: params })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDematTableInvestors = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/opp-class-demat-table/investors', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveSchemeExpenses = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/scheme-expenses/create', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSchemeExpenses = (params) => {

  return new Promise((resolve, reject) => {

    httpGet({ path: '/admin/scheme-expenses', param: params })
      .then((res) => {

        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }

        return reject(res.description)

      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeleteSchemeExpenses = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/delete-scheme-expenses/' + reqParams.seRid, body: null })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDeleteFatca = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/fatca/delete', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveFatca = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/fatca', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiFatca = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/fatca', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiSaveMassEmail = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/mass-email', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiGetMassEmail = () => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/mass-email/mass-email-summary' })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiGetMassEmailLog = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/mass-email/mass-email-log', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

//api for download demat list
export const apiDematListDownload = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/demat-list-download', param: params })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

export const apiDematHoldingsDownload = (params) => {
  return new Promise((resolve, reject) => {
    getFile({ path: '/admin/demat-holdings-list-download', param: params })
      .then((res) => {
        return resolve(res);
      }).catch((e) => {
       return reject(e);
      })
  });
};

export const apiCreateSchemeIncome = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/create-scheme-income', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export const apiGetSchemeIncome = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/scheme-income', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export const apiDeleteSchemeIncome = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpPut({ path: '/admin/delete-scheme-income/' + reqParams.siRid, body: null })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {
          return resolve(res.description);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

// api for get nsdl and cdsl investor list

export const apiGetRecordMassDemat = (oppRid) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: `/admin/get-demat-record-list/${oppRid}` })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

// api for save mass demat details
export const apiSaveMassDematRecord = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: '/admin/save-mass-demat-detail', body: body })
      .then((res) => {
        if (res.status === STATUS_SUCCESS) {

          return resolve(res);

        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export const apiGetDshbordCardData = () => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/get-dashboard-card-data' })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export const apiGetDshbordGraphData = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/get-dashboard-graph-data', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data);
        }
        return reject(res.description);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export const getCountryNames = async (countryCode) => {
  const JWT = localStorage.getItem("JWT");

  return new Promise((resolve, reject) => {
    httpGet({
      path: `/admin/countries?countryCode=${countryCode}`,
      JWT: JWT,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export const getApiCountryByRid = async (countryRid) => {
  // const JWT = localStorage.getItem("JWT");

  return new Promise((resolve, reject) => {
    httpGet({
      path: `/admin/countries-by-id?countryRid=${countryRid}`
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export const getApiCityByRid = async (cityRid) => {
  // const JWT = localStorage.getItem("JWT");

  return new Promise((resolve, reject) => {
    httpGet({
      path: `/admin/city-by-id?cityRid=${cityRid}`
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export const getCityNames = async (countryRid, page = 1, limit = 20, searchQuery = "") => {
  const JWT = localStorage.getItem("JWT");
  return new Promise((resolve, reject) => {
    httpGet({
      path: `/admin/cities?countryRid=${countryRid}&page=${page}&limit=${limit}&searchQuery=${searchQuery}`,
      JWT: JWT,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const apiGetDematHoldings = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/list-demat-holdings', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}


export const getOpportunitySimple = (params) => {
  return new Promise((resolve, reject) => {
    httpGet({ path: '/admin/opportunity-simple', param: params })
      .then((res) => {
        if (res.status === STATUS_SUCCESS && res.data) {
          return resolve(res.data)
        }
        return reject(res.description)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}