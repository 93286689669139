import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Checkbox,
  CircularProgress, Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Tab, Tabs, Tooltip, Chip
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory, useLocation } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography, Skeleton } from "@mui/material";
import SchemeInvestorModal from "./SchemeInvestorModal";
import { RECORD_DISPLAY_LIMIT } from "../../utils/constants/constants";
import {
  apiDematListDownload,
  apiInvestorOpportunity,
  apiRejectDraftInvestment, apiSchemeCancel,
  apiSchemeDetails,
  apiUnitAllotment,
  apiUnitAllotmentFile,
  apiUpdateInvestorOpportunity,
  getPartners,
} from "../../utils/api/api";
import {
  BASE_URL,
  CDSL,
  DRAWDOWN_FAILED,
  DRAWDOWN_PENDING,
  DRAWDOWN_SENT,
  DRAWDOWN_UNSENT,
  NSDL,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  TAB_ALL_APPROVED,
  TAB_DEMAT,
  TAB_PENDING,
} from "../../utils/constants/constants";
import { useSnackbar } from "notistack";
import {
  formatDateTime,
  isSubAdmin,
  toLocalePrice,
  toLocalePriceNoDecimal,
} from "../../utils/StringUtil";
import FundTransferDialog from "./FundTransferDialog";
import UnitAllotDocs from "./UnitAllotDocs";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FundReceivedDialog from "./FundReceivedDialog";
import AlertLayout from "../../components/layout/AlertLayout";
import TransactionDocuments from "./TransactionDocuments";
import InfoIcon from "@mui/icons-material/Info";
import SchemeInvestorInfo from "./SchemeInvestorInfo";
import InvestorOnboarding from "./InvestorOnboarding";
import CircleIcon from "@mui/icons-material/Circle";
import UnitAllotFile from "./UnitAllotFile";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ApproveDraftInvestment from './ApproveDraftInvestment';
import RecordDematerialization from "./record-demat/RecordDematerialization";
import DematDetailsDialog from "./record-demat/DematDetailsDialog";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import GetAppIcon from '@mui/icons-material/GetApp';
import MassDematUploadDailog from "./record-demat/MassDematUploadDailog";
import { CREDITED, PENDING, ESCROW } from "../../utils/constants/constants";

const SchemeInvestor = () => {
  const location = useLocation();
  const { schemeRid } = location.state;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [dematAnchorEl, setDematAnchorEl] = useState(null);
  const openDematMenu = Boolean(dematAnchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [investorList, setInvestorList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editInvestor, setEditInvestor] = useState(null);
  const [openFundReceived, setOpenFundReceived] = useState(false);
  const [openFundDialog, setOpenFundDialog] = useState(false);
  const [openUnitAllotDialog, setOpenUnitAllotDialog] = useState(false);
  const [openTransactionDocs, setOpenTransactionDocs] = useState(false);
  const [filterDrawdown, setFilterDrawdown] = useState(-1);
  const [filterConsent, setFilterConsent] = useState(-1);
  const [filterPayment, setFilterPayment] = useState(-1);
  const [filesUnitAllot, setFilesUnitAllot] = useState([]);
  const [openSchemeInvInfo, setOpenSchemeInvInfo] = useState(false);

  const [valCommitmentReceived, setValCommitmentReceived] = useState(0.0);
  const [valFundReceived, setValFundReceived] = useState(0.0);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertLoader, setAlertLoader] = useState(false);
  const [openSchemeCancel, setOpenSchemeCancel] = useState(false);
  const [openUnitAllotFile, setOpenUnitAllotFile] = useState(false);
  const [openInvestorOnboarding, setOpenInvestorOnboarding] = useState(false);
  const [openRecordDemat, setOpenRecordDemat] = useState(false);
  const [scheme, setScheme] = useState({});
  const userType = process.env.REACT_APP_USER_TYPE;

  const [selectedTab, setSelectedTab] = useState(0);
  const [openRejectDraftInvestment, setOpenRejectDraftInvestment] = useState(false);
  const [openApproveDraftInvestment, setOpenApproveDraftInvestment] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);

  const [invOppVals, setInvOppVals] = useState({});
  const [openDematDetails, setOpenDematDetails] = useState(false);

  const [filterPartner, setFilterPartner] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  const [openRecordMassDemat, setOpenRecordMassDemat] = useState(false);
  const [filterDematStatus, setFilterDematStatus] = useState("all");

  const toggleDematDetailsDialog = (ioRid) => {
    if (openDematDetails) {
      setEditInvestor(null);
      setOpenDematDetails(false);
    } else {
      setEditInvestor(investorList.find(item => item?.ioRid === ioRid));
      setOpenDematDetails(true);
    }
  }
  const handleDematDetailsSave = () => {
    toggleDematDetailsDialog();
    getInvestorOpportunity();
  }

  const handleOpen = () => {
    setOpenModal(true);
    setEditInvestor(null);
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };

  const handleSave = () => {
    getInvestorOpportunity();
    handleClose();
  };

  const handleEdit = (data) => {
    setEditInvestor(data);
    setOpenModal(true);
    setIsEdit(true);
  };

  const toggleSchemeInvInfo = (data) => {
    if (openSchemeInvInfo) {
      setOpenSchemeInvInfo(false);
    } else {
      setEditInvestor(data);
      setOpenSchemeInvInfo(true);
    }
  };

  const toggleAlertDialog = () => {
    if (openAlertDialog) {
      setOpenAlertDialog(false);
    } else {
      setOpenAlertDialog(true);
    }
  };
  const handleAlertDialog = () => {
    setAlertLoader(true);
    editInvestor.status = 0;
    apiUpdateInvestorOpportunity(editInvestor)
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        getInvestorOpportunity();
        toggleAlertDialog();
      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const toggleSchemeCancel = () => {
    if (openSchemeCancel) {
      setOpenSchemeCancel(false);
    } else {
      setOpenSchemeCancel(true);
      setAnchorEl(null);
    }
  };
  const handleSchemeCancel = () => {
    setAlertLoader(true);
    apiSchemeCancel({
      oppRid: schemeRid,
    })
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        getSchemeDetails();
        getInvestorOpportunity();
        toggleSchemeCancel();
      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const toggleUnitAllotFile = () => {
    if (openUnitAllotFile) {
      setOpenUnitAllotFile(false);
    } else {
      setOpenUnitAllotFile(true);
      setAnchorEl(null);
    }
  };
  const handleUnitAllotFileSave = () => {
    toggleUnitAllotFile();
  };

  const toggleTransactionDocs = () => {
    if (openTransactionDocs) {
      setOpenTransactionDocs(false);
    } else {
      setOpenTransactionDocs(true);
      setAnchorEl(null);
    }
  };

  const handleTransactionDocsSave = () => {
    getSchemeDetails();
    getInvestorOpportunity();
    toggleTransactionDocs();
  };

  const toggleFundReceived = () => {
    if (openFundReceived) {
      setOpenFundReceived(false);
    } else {
      setOpenFundReceived(true);
      setAnchorEl(null);
    }
  };
  const handleFundReceivedSave = () => {
    getInvestorOpportunity();
    toggleFundReceived();
  };

  const toggleFundDialog = () => {
    if (openFundDialog) {
      setOpenFundDialog(false);
    } else {
      setOpenFundDialog(true);
      setAnchorEl(null);
    }
  };
  const handleFundDialogSave = () => {
    getSchemeDetails();
    getInvestorOpportunity();
    toggleFundDialog();
  };

  const toggleUnitAllotDialog = () => {
    if (openUnitAllotDialog) {
      setOpenUnitAllotDialog(false);
    } else {
      setOpenUnitAllotDialog(true);
      setAnchorEl(null);
    }
  };
  const handleUnitAllotSave = () => {
    getSchemeDetails();
    getInvestorOpportunity();
    toggleUnitAllotDialog();
  };

  const toggleInvestorOnboarding = () => {
    if (openInvestorOnboarding) {
      setOpenInvestorOnboarding(false);
    } else {
      setOpenInvestorOnboarding(true);
      setAnchorEl(null);
    }
  };
  const handleInvestorOnboardingSave = () => {
    toggleInvestorOnboarding();
  };

  const toggleApproveDraftInvestment = (data) => {
    if (openApproveDraftInvestment) {
      setOpenApproveDraftInvestment(false);
    } else {
      setEditInvestor(data);
      setOpenApproveDraftInvestment(true);
    }
  }
  const handleApproveDraftInvestmentSave = () => {
    // getDraftInvestorOpportunity();
    toggleApproveDraftInvestment();
    getInvestorOpportunity();
  }

  const toggleRecordDemat = () => {
    if (openRecordDemat) {
      setOpenRecordDemat(false);
    } else {
      setOpenRecordDemat(true);
      setAnchorEl(null);
    }
  };

  const toggleRecordMassDemat = () => {
    if (openRecordMassDemat) {
      setOpenRecordMassDemat(false);
      getInvestorOpportunity();
    } else {
      setOpenRecordMassDemat(true);
      setDematAnchorEl(null);
    }
  }
  const handleRecordDematSave = () => {
    getSchemeDetails();
    toggleRecordDemat();
    getInvestorOpportunity();
  }

  const handleRecordMassDematSave = () => {
    getSchemeDetails();
    toggleRecordMassDemat();
    getInvestorOpportunity();
  }

  const openDocExplorer = () => {
    document.getElementById("input-file-multiple").click();
    setAnchorEl(null);
  };

  const uploadDocuments = (e) => {
    setFilesUnitAllot(Object.values(e.target.files));
    toggleUnitAllotDialog();
  };

  const getSchemeDetails = (param = {}) => {
    param.oppRid = schemeRid;
    apiSchemeDetails(param)
      .then((data) => {
        setScheme(data);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };


  const DematStatusFilter = ({ filterDematStatus, setFilterDematStatus, isLoading, onStatusChange }) => {
    const getStatusColor = (status) => {
      switch (status) {
        case CREDITED:
          return '#2E7D32';
        case PENDING:
          return '#FF7518';
        case ESCROW:
          return '#b71c1c';
        default:
          return '#00000000';
      }
    };

    const handleChange = (event) => {
      const newStatus = event.target.value;
      setFilterDematStatus(newStatus);
      if (onStatusChange) {
        onStatusChange(newStatus);
      }
    };
    return (
      <FormControl sx={{ width: 180 }} size="small" color="secondary">
        <InputLabel id="demat-status-select-label">Demat Status</InputLabel>
        <Select
          labelId="demat-status-select-label"
          id="demat-status-select"
          value={filterDematStatus}
          label="Demat Status"
          onChange={handleChange}
          disabled={isLoading}
          sx={{ fontSize: '0.975rem' }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {(selected === CREDITED || selected === PENDING || selected === ESCROW) && (
                <FiberManualRecordIcon
                  sx={{
                    fontSize: 15,
                    color: getStatusColor(selected)
                  }}
                />
              )}
              <Typography variant="body2" sx={{ fontSize: '0.975rem', fontWeight: 400 }}>
                {selected === CREDITED ? 'Credited' :
                  selected === PENDING ? 'Pending' :
                    selected === ESCROW ? 'Escrow' : 'All'}
              </Typography>
            </Box>
          )}
        >
          <MenuItem value="all">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" sx={{ fontSize: '0.975rem' }}>All</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={CREDITED}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FiberManualRecordIcon sx={{ fontSize: 14, color: '#2E7D32' }} />
              <Typography variant="body2" sx={{ fontSize: '0.975rem' }}>Credited</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={PENDING}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FiberManualRecordIcon sx={{ fontSize: 14, color: '#FF7518' }} />
              <Typography variant="body2" sx={{ fontSize: '0.975rem' }}>Pending</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={ESCROW}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FiberManualRecordIcon sx={{ fontSize: 14, color: '#b71c1c' }} />
              <Typography variant="body2" sx={{ fontSize: '0.975rem' }}>Escrow</Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };
  const getInvestorOpportunity = (param = {}) => {
    setInvestorList([]);
    setIsLoading(true);
    param.oppRid = schemeRid;
    param.drawdown = filterDrawdown < 0 ? null : filterDrawdown;
    param.consent = filterConsent < 0 ? null : filterConsent;
    param.fundStatus = filterPayment < 0 ? null : filterPayment;
    param.pageNumber = 1;
    param.invSource = filterPartner.map((item) => item).join(",");
    if (selectedTab === TAB_DEMAT && filterDematStatus != "all") {
      param.dematStatus = filterDematStatus;
    }
    apiInvestorOpportunity(param)
      .then((data) => {
        setIsLoading(false);
        setValCommitmentReceived(data.commitments);
        setValFundReceived(data.fundReceived);
        setPendingCount(data?.pendingCount);
        setInvOppVals({
          escrow: data?.escrow,
          nsdl: data?.nsdl,
          cdsl: data?.cdsl,
          records: data?.records,
          classDematerializationCount: data?.classDematerializationCount,
          dematUpdated: data?.dematUpdated,
        });

        if (selectedTab !== TAB_PENDING) {
          setInvestorList(data.investorOpportunities);
        }
        // if (selectedTab === TAB_PENDING) {
        //   if (data.investorOpportunities?.length > 0 && data.investorOpportunities.fundStatus === 2) {

        //     setInvestorList(data.draftInvestments);
        //   }
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  // const getDraftInvestorOpportunity = (param = {}) => {
  //   setInvestorList([]);
  //   setIsLoading(true);
  //   param.oppRid = schemeRid;
  //   apiDraftInvOppList(param)
  //     .then((data) => {
  //       setIsLoading(false);

  //       if (selectedTab === TAB_PENDING) {
  //         if (data.draftInvestments?.length > 0 && data) {

  //           setInvestorList(data.draftInvestments);
  //         } else {
  //           setSelectedTab(TAB_ALL_APPROVED);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       enqueueSnackbar(error, SNACKBAR_ERROR);
  //     });
  // }

  useEffect(() => {
    if (selectedTab === TAB_PENDING) {
      setSelectedTab(0);
    } else {
      getInvestorOpportunity();
    }
  }, [filterDrawdown, filterConsent, filterPayment, filterPartner, filterDematStatus]);
  useEffect(() => {
    if (selectedTab === TAB_PENDING) {
      getInvestorOpportunity();
    } else {
      getInvestorOpportunity();
    }
  }, [selectedTab]);

  useEffect(() => {
    getSchemeDetails();
    getPartnersList();
  }, []);

  const deleteSchemeInvestor = (schemeInvestor) => {
    setEditInvestor(schemeInvestor);
    toggleAlertDialog();
  }

  const toggleRejectDraftInvestment = (data) => {
    if (openRejectDraftInvestment) {
      setOpenRejectDraftInvestment(false);
    } else {
      setEditInvestor(data);
      setOpenRejectDraftInvestment(true);
    }
  }
  // const handleRejectDraftInvestment = (param = {}) => {
  //   param.diRid = editInvestor?.diRid;
  //   apiRejectDraftInvestment(param)
  //     .then((data) => {
  //       toggleRejectDraftInvestment();
  //       enqueueSnackbar(data, SNACKBAR_SUCCESS);
  //       getDraftInvestorOpportunity();
  //       getInvestorOpportunity();
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(error, SNACKBAR_ERROR);
  //     });
  // }

  const getPartnersList = () => {
    setPartnerList([]);
    getPartners()
      .then((res) => {
        setPartnerList(res.partnerMasterList);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  const downloadFile = async (response) => {
    const blob = new Blob([response.data], { type: response.headers.get('content-type') });
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download', response.headers.get('content-disposition').split('"')[1]);
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  const downloadDematList = (param = {}) => {
    param.oppRid = schemeRid;
    param.drawdown = filterDrawdown < 0 ? null : filterDrawdown;
    param.consent = filterConsent < 0 ? null : filterConsent;
    param.fundStatus = filterPayment < 0 ? null : filterPayment;
    param.pageNumber = 1;
    param.invSource = filterPartner.map((item) => item).join(",");

    apiDematListDownload(param)
      .then((data) => {
        downloadFile(data);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  }

  return (
    <Layout title="Scheme">
      <AlertLayout
        open={openAlertDialog}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleAlertDialog}
        positiveAction={{ label: "Delete", onClick: handleAlertDialog }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently delete.
        </p>
      </AlertLayout>

      <AlertLayout
        open={openSchemeCancel}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleSchemeCancel}
        positiveAction={{ label: "Proceed", onClick: handleSchemeCancel }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently Cancel Scheme.
        </p>
      </AlertLayout>

      {/* <AlertLayout
        open={openRejectDraftInvestment}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleRejectDraftInvestment}
        positiveAction={
          { label: 'Reject', onClick: handleRejectDraftInvestment }
        }>
        <p className="fs-large-medium p-0 m-0">This action cannot be undone. This will permanently delete.</p>
      </AlertLayout> */}
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
          className={
            scheme.fundTransferred ||
              scheme.oppStatusIndex === 1503 ||
              scheme.oppStatusIndex === 1504
              ? "d-none"
              : ""
          }
        >
          <AddIcon />
        </Button>
      )}

      <SchemeInvestorInfo
        open={openSchemeInvInfo}
        onClose={toggleSchemeInvInfo}
        scheme={scheme}
        investor={editInvestor}
      />
      <SchemeInvestorModal
        open={openModal}
        onClose={handleClose}
        isEdit={isEdit}
        scheme={scheme}
        investor={editInvestor}
        handleSave={() => handleSave()}
      />
      <FundReceivedDialog
        open={openFundReceived}
        toggleDialog={toggleFundReceived}
        scheme={scheme}
        handleSave={handleFundReceivedSave}
      />
      <FundTransferDialog
        open={openFundDialog}
        toggleDialog={toggleFundDialog}
        scheme={scheme}
        handleSave={handleFundDialogSave}
      />
      <UnitAllotDocs
        open={openUnitAllotDialog}
        toggleDialog={toggleUnitAllotDialog}
        scheme={scheme}
        files={filesUnitAllot}
        handleSave={handleUnitAllotSave}
      />
      <TransactionDocuments
        open={openTransactionDocs}
        toggleDialog={toggleTransactionDocs}
        scheme={scheme}
        handleSave={handleTransactionDocsSave}
      />
      <UnitAllotFile
        open={openUnitAllotFile}
        toggleDialog={toggleUnitAllotFile}
        scheme={scheme}
        handleSave={handleUnitAllotFileSave}
      />
      <InvestorOnboarding
        open={openInvestorOnboarding}
        toggleDialog={toggleInvestorOnboarding}
        scheme={scheme}
        handleSave={handleInvestorOnboardingSave}
      />

      <ApproveDraftInvestment
        open={openApproveDraftInvestment}
        toggleDialog={toggleApproveDraftInvestment}
        invOpp={editInvestor}
        handleSave={handleApproveDraftInvestmentSave}
      />

      <RecordDematerialization
        open={openRecordDemat}
        toggleDialog={toggleRecordDemat}
        scheme={scheme}
        handleSave={handleRecordDematSave}
      />

      <DematDetailsDialog
        open={openDematDetails}
        toggleDialog={toggleDematDetailsDialog}
        invOpp={editInvestor}
        handleSave={handleDematDetailsSave}
      />

      <MassDematUploadDailog
        scheme={scheme}
        handleSave={handleRecordMassDematSave}
        open={openRecordMassDemat}
        toggleDialog={toggleRecordMassDemat}
      />

      <div className="p-4">
        <div className="d-flex gap-5 justfy-content-between align-items-start mb-4">
          <div
            className="flex-grow-1 bg-white p-3"
            style={{ borderRadius: "5px" }}
          >
            <h3 className="text-dark fs-large-semibold mb-3">
              {scheme.oppTitle}
            </h3>
            <div className="d-flex flex-wrap" style={{ gap: "10px 40px" }}>
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold">Round Size:</span>{" "}
                {toLocalePrice(scheme.roundSize / 10000000)}cr
              </p>
              <p className="fs-normal-regular p-0 m-0 m-0">
                <span className="fs-normal-semibold">Domain:</span>{" "}
                {scheme.domain}
              </p>
              <p className="fs-normal-regular m-0">
                <span className="fs-normal-semibold">Investment Round:</span>{" "}
                {scheme.invRound}
              </p>
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold">Fund Transfered:</span>{" "}
                {scheme.fundTransferred ? (
                  <DoneIcon fontSize="small" color="success" />
                ) : (
                  <CloseIcon fontSize="small" color="error" />
                )}
              </p>
              {/* <p className="fs-normal-regular p-0 m-0">
                            <span className="fs-normal-semibold">Unit Allotted: </span>
                            {(scheme.oppStatusIndex === 1503 && investorList.length > 0) ? (
                                scheme.uaPending === 0 ? <CircleIcon fontSize="small" color="success" /> : <span className="text-danger">{scheme.uaTotal - scheme.uaPending}/{scheme.uaTotal}</span>
                            ) : <CircleIcon fontSize="small" color="disabled" />}
                        </p> */}
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold">Status:</span>{" "}
                {scheme.oppStatus}
              </p>
              {/* <p className="fs-normal-regular p-0 m-0">
                            <span className="fs-normal-semibold">Closure Date:</span> {formatDateTime(scheme.fundingEndDate, false)}
                        </p> */}
            </div>
            <div className="d-flex flex-wrap mt-2" style={{ gap: "10px 40px" }}>
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold text-primary">Escrow:</span> {invOppVals?.escrow}
              </p>
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold text-primary">NSDL:</span> {invOppVals?.nsdl}
              </p>
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold text-primary">CDSL:</span> {invOppVals?.cdsl}
              </p>
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold text-primary">Total Investors:</span> {invOppVals?.records}
              </p>
              {/* <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold text-primary">Total Class Dematerialization:</span> {invOppVals?.classDematerializationCount}
              </p> */}
              <p className="fs-normal-regular p-0 m-0">
                <span className="fs-normal-semibold text-primary">Demat Updated On:</span> {invOppVals?.dematUpdated ? formatDateTime(invOppVals?.dematUpdated, true) : '--/--/----'}
              </p>
            </div>
          </div>
          <IconButton
            onClick={() =>
              history.replace(
                userType === "ADMIN" ? "/admin/scheme" : "/partner/scheme"
              )
            }
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
        </div>

        <div
          className="d-flex gap-3 flex-column bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex justfy-content-between align-items-center">
            <div className="d-flex gap-3 align-item-center flex-row flex-grow-1">
              <FormControl sx={{ width: 200 }} size="small" color="secondary">
                <InputLabel id="demo-simple-select-label">Drawdown</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterDrawdown}
                  label="Drawdown"
                  onChange={(e) => setFilterDrawdown(parseInt(e.target.value))}
                >
                  <MenuItem value="-1">All</MenuItem>
                  <MenuItem value="0">Pending</MenuItem>
                  <MenuItem value="1">Sent</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: 200 }} size="small" color="secondary">
                <InputLabel id="demo-simple-select-label">Consent</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterConsent}
                  label="Consent"
                  onChange={(e) => setFilterConsent(parseInt(e.target.value))}
                >
                  <MenuItem value="-1">All</MenuItem>
                  <MenuItem value="0">Pending</MenuItem>
                  <MenuItem value="1">Received</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: 200 }} size="small" color="secondary">
                <InputLabel id="demo-simple-select-label">Payment</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterPayment}
                  label="Payment"
                  onChange={(e) => setFilterPayment(parseInt(e.target.value))}
                >
                  <MenuItem value="-1">All</MenuItem>
                  <MenuItem value="0">Pending</MenuItem>
                  <MenuItem value="1">Received</MenuItem>
                </Select>
              </FormControl>
              {userType === "ADMIN" && (
                <FormControl sx={{ width: 180 }} size="small">
                  <InputLabel id="demo-multiple-checkbox-label">
                    Filter By Partner
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterPartner}
                    onChange={(e) => setFilterPartner(e.target.value)}
                    input={<OutlinedInput label="Filter By Partner" />}
                    renderValue={(selected) => `${selected.length} Selected`}
                  >
                    {partnerList.map((data, key) => (
                      <MenuItem key={key} value={data.partnerRid}>
                        <Checkbox
                          checked={filterPartner.indexOf(data.partnerRid) > -1}
                        />
                        <ListItemText primary={data.fullName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {selectedTab === TAB_DEMAT && (
                <DematStatusFilter
                  filterDematStatus={filterDematStatus}
                  setFilterDematStatus={setFilterDematStatus}
                  isLoading={isLoading}
                />
              )}


            </div>
            <input
              className="d-none"
              id="input-file-multiple"
              type="file"
              accept=".pdf"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={(e) => {
                uploadDocuments(e);
              }}
              multiple
            />
            <IconButton
              color="secondary"
              id="basic-button"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              className={scheme.oppStatusIndex === 1504 ? "d-none" : ""}
            >
              <MoreVertIcon fontSize="medium" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              elevation={3}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {userType === "ADMIN" && !isSubAdmin() && (
                <MenuItem className="fs-large-regular" onClick={openDocExplorer}>
                  Upload Unit Allotments
                </MenuItem>
              )}
              {userType === "ADMIN" && !isSubAdmin() && (
                <MenuItem className="fs-large-regular" onClick={toggleFundDialog}>
                  Mark as Fund Transferred to Company
                </MenuItem>
              )}
              {userType === "ADMIN" && !isSubAdmin() &&
                (scheme.fundTransferred || scheme.oppStatusIndex === 1503 ? (
                  ""
                ) : (
                  <MenuItem
                    className="fs-large-regular"
                    onClick={toggleFundReceived}
                  >
                    Fund Received
                  </MenuItem>
                ))}
              {userType === "ADMIN" && !isSubAdmin() && (
                <MenuItem
                  className="fs-large-regular"
                  onClick={toggleTransactionDocs}
                >
                  Transaction Documents
                </MenuItem>
              )}
              {investorList.length > 0 && (
                <MenuItem
                  className="fs-large-regular"
                  onClick={toggleUnitAllotFile}
                >
                  Download Unit Allotment Excel
                </MenuItem>
              )}
              {investorList.length > 0 && (
                <MenuItem
                  className="fs-large-regular"
                  onClick={toggleInvestorOnboarding}
                >
                  Download Investor Onboarding Excel
                </MenuItem>
              )}
              {(scheme.fundTransferred ? (
                <MenuItem
                  className="fs-large-regular"
                  onClick={toggleRecordDemat}
                >
                  Record Dematerialization
                </MenuItem>
              ) : '')}
              {userType === "ADMIN" && !isSubAdmin() &&
                (scheme.fundTransferred ? (
                  ""
                ) : (
                  <MenuItem
                    className="fs-large-regular"
                    onClick={toggleSchemeCancel}
                  >
                    Cancel Scheme
                  </MenuItem>
                ))}
            </Menu>
          </div>
        </div>

        <div className="d-flex gap-5 align-items-center mb-3">
          <p className="section-label p-0 m-0">Investors</p>
          <div className="d-flex gap-3 align-items-center justify-content-end flex-grow-1">
            <p className="fs-normal-regular text-muted p-0 m-0">
              Commitment Received:
              <span className="fs-med-semibold ms-1">
                ₹{toLocalePriceNoDecimal(valCommitmentReceived)}
              </span>
            </p>
            <p className="fs-normal-regular text-muted p-0 m-0">
              Fund Received:
              <span className="fs-med-semibold ms-1">
                ₹{toLocalePriceNoDecimal(valFundReceived)}
              </span>
            </p>
          </div>
        </div>

        <div className="d-flex gap-2 flex-column bg-white py-2 px-3 mb-5" style={{ borderRadius: '5px' }}>
          <div className="d-flex">
            <div>
              <Tabs
                aria-label="basic tabs example"
                value={selectedTab}
                onChange={(e, v) => setSelectedTab(v)}
              >
                <Tab label="All (Approved)" value={TAB_ALL_APPROVED} />
                {/* {(userType === 'ADMIN' && pendingCount > 0) && <Tab label={`Pending (${pendingCount})`} value={TAB_PENDING} />} */}
                {(scheme?.fundTransferred && invOppVals?.classDematerializationCount > 0) ? <Tab label="Demat" value={TAB_DEMAT} /> : ''}
              </Tabs>
            </div>

            {
              [TAB_DEMAT].includes(selectedTab) ?
                <div className="ms-auto d-flex justify-content-end">
                  <IconButton
                    color="secondary"
                    id="basic-button"
                    aria-controls={openDematMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDematMenu ? "true" : undefined}
                    onClick={(e) => setDematAnchorEl(e.currentTarget)}
                    className={scheme.oppStatusIndex === 1504 ? "d-none" : ""}
                  >
                    <MoreVertIcon fontSize="medium" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={dematAnchorEl}
                    open={openDematMenu}
                    elevation={3}
                    onClose={() => setDematAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem sx={{ textTransform: 'none' }} onClick={(e) => downloadDematList()} startIcon={<GetAppIcon />}>
                      Export to Excel
                    </MenuItem>
                    <MenuItem className="fs-large-regular" onClick={toggleRecordMassDemat}> Mass Demat Upload </MenuItem>
                  </Menu>
                </div>
                : null
            }
          </div>

          {
            isLoading ? <CircularProgress /> :
              <>
                {
                  [TAB_ALL_APPROVED].includes(selectedTab) ?
                    <table className="table table-striped table-borderless w-100">
                      <thead>
                        <tr className="fs-normal-semibold text-center">
                          <th scope="col"></th>
                          <th scope="col">
                            <p className="mb-2">Investor</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2 text-end">Commitment</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2">Class</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2">Upfront</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2">Drawdown</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2">Consent</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2">Fund</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2">Unit Allot</p>
                          </th>
                          <th scope="col">
                            <p className="mb-2 text-end">Investor Source</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {investorList.map((data, key) => {
                          return (
                            <tr className="fs-normal-medium text-center" key={key}>
                              <td className="pt-1">
                                <div className="d-flex">
                                  <Tooltip title="More Info">
                                    <IconButton onClick={() => toggleSchemeInvInfo(data)}>
                                      <InfoIcon fontSize="small" className="text-blue" />
                                    </IconButton>
                                  </Tooltip>
                                  {(scheme.fundTransferred || scheme.oppStatusIndex === 1503 || scheme.oppStatusIndex === 1504) ? '' :
                                    <>
                                      {userType === 'ADMIN' && !isSubAdmin() && (
                                        <IconButton onClick={() => handleEdit(data)} aria-label="edit">
                                          <EditIcon fontSize="small" className="text-blue" />
                                        </IconButton>
                                      )}
                                      {userType === 'ADMIN' && !isSubAdmin() && (
                                        <IconButton onClick={() => deleteSchemeInvestor(data)}>
                                          <DeleteOutlineIcon fontSize="small" className="text-danger" />
                                        </IconButton>
                                      )}
                                    </>}
                                </div>
                              </td>
                              <td className="pt-3">
                                {data.invFullName}
                                <span className="fs-small-regular text-muted ms-2">{data.invCode}</span>
                              </td>
                              <td className="pt-3 text-end">₹{toLocalePrice(data.commitment)}</td>
                              <td className="pt-3">{data.className}</td>

                              {
                                data?.unitsTransferredStatus === 1
                                  ? <td className="text-center pt-3" colSpan="4">
                                    TRANSFERRED IN
                                  </td>
                                  : data?.unitsTransferredStatus === 2
                                    ? <td className="text-center pt-3" colSpan="4">
                                      TRANSFERRED OUT
                                    </td>
                                    : <>
                                      <td className="pt-3">{toLocalePrice(data.upfront)}%</td>
                                      <td className="pt-3">
                                        {data.drawdown === DRAWDOWN_UNSENT ? 'UnSent' :
                                          data.drawdown === DRAWDOWN_SENT && (data.drawdownFile ?
                                            <Link color="secondary" target="_blank" href={`${BASE_URL}/${JSON.parse(data.drawdownFile).path}`}>Sent</Link> : 'Sent'
                                          )
                                        }
                                        {data.drawdown === DRAWDOWN_PENDING ? 'Pending' : ''}
                                        {data.drawdown === DRAWDOWN_FAILED ? 'Failed' : ''}
                                      </td>
                                      <td className="pt-3">{data.consent ? 'Received' : 'Pending'}</td>
                                      <td className="pt-3">{data.fundStatus === 1 ? 'Received' : 'Pending'}</td>
                                    </>
                              }

                              <td className="pt-3">{data.unitAllotted ? 'Allotted' : 'Pending'}</td>

                              <td className="pt-3 text-end">{data.invSourceName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> : ''
                }
                {isLoading ? (
                  <table className="table table-striped table-borderless w-100">
                    <thead>
                      <tr className="fs-normal-semibold text-center">
                        {/* Maintain the same number of columns as the actual table */}
                        <th scope="col"></th>
                        <th scope="col"><p className="mb-2">Investor Name</p></th>
                        <th scope="col"><p className="mb-2">Investor Code</p></th>
                        <th scope="col"><p className="mb-2 text-center">DP ID</p></th>
                        <th scope="col"><p className="mb-2 text-center">Client ID</p></th>
                        <th scope="col"><p className="mb-2 text-end">Quantity(Units)</p></th>
                        <th scope="col"><p className="mb-2 text-center">ISIN Code</p></th>
                        <th scope="col"><p className="mb-2 text-center">Class</p></th>
                        <th scope="col"><p className="mb-2 text-end">Commitment</p></th>
                        <th scope="col"><p className="mb-2 text-end">Status</p></th>
                        <th scope="col"><p className="mb-2 text-end">Demat Status</p></th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
                        <tr key={index} className="fs-normal-medium text-center">
                          <td className="pt-1">
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={80}
                              height={40}
                            />
                          </td>
                          {[...Array(10)].map((_, colIndex) => (
                            <td key={colIndex} className="pt-3">
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width="100%"
                                height={30}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  [TAB_DEMAT].includes(selectedTab) ?
                    <>
                      <table className="table table-striped table-borderless w-100">
                        <thead>
                          <tr className="fs-normal-semibold text-center">
                            <th scope="col"></th>
                            <th scope="col">
                              <p className="mb-2">Investor Name</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2">Investor Code</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-center">DP ID</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-center">Client ID</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-end">Quantity(Units)</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-center">ISIN Code</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-center">Class</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-end">Commitment</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-end">Status</p>
                            </th>
                            <th scope="col">
                              <p className="mb-2 text-end">Demat Status</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {investorList.map((data, key) => {
                            if (data.commitment === 0) {
                              return null;
                            }
                            return (
                              <tr className="fs-normal-medium text-center" key={key}>
                                <td className="pt-1">
                                  <div className="d-flex">
                                    <Tooltip title="More Info">
                                      <IconButton onClick={() => toggleSchemeInvInfo(data)}>
                                        <InfoIcon fontSize="small" className="text-blue" />
                                      </IconButton>
                                    </Tooltip>
                                    {[NSDL, CDSL].includes(data?.invNsdlCdsl) && userType === 'ADMIN' && !isSubAdmin() && (
                                      <IconButton onClick={() => toggleDematDetailsDialog(data?.ioRid)} aria-label="edit">
                                        <EditIcon fontSize="small" className="text-blue" />
                                      </IconButton>
                                    )}
                                  </div>
                                </td>
                                <td className="pt-3">
                                  {data.invFullName}
                                </td>
                                <td className="pt-3">{data.invCode}</td>
                                <td className="pt-3 text-center">{data.invNsdlCdsl !== "ESCROW" ? data.dpID : "---"}</td>
                                <td className="pt-3 text-center">{data.invNsdlCdsl !== "ESCROW" ? data.dpClientID : "---"}</td>
                                <td className="pt-3 text-end">
                                  {data.unitsAllotted ? (
                                    <p>{(data.unitsAllotted).toFixed(3)}</p>
                                  ) : (
                                    <p className="text-warning">{(data.commitment / 100000).toFixed(3)}</p>
                                  )}
                                </td>
                                <td className="pt-3 text-center">
                                  {data?.isin ?? '---'}
                                </td>
                                <td className="pt-3 text-center">{data.className}</td>
                                <td className="pt-3 text-end">{data.commitment}</td>
                                <td className="pt-3 text-end">
                                  {data.invNsdlCdsl}
                                </td>
                                <td className="pt-3 text-end">
                                  {data.dematStatus === ESCROW ? (
                                    <Chip
                                      label="ESCROW"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#d32f2f",
                                        color: "white",
                                        borderColor: "#b71c1c",
                                        fontWeight: "medium",
                                        height: "24px",
                                        minWidth: "70px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        "& .MuiChip-label": {
                                          color: "white",
                                          fontSize: "0.75rem",
                                          padding: "0 8px",
                                          lineHeight: 1,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                          paddingTop: "1px"
                                        }
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label={data.dematStatus}
                                      size="small"
                                      color={
                                        data.dematStatus === CREDITED
                                          ? "success"
                                          : "warning"
                                      }
                                      variant="outlined"
                                      sx={{
                                        height: "24px",
                                        minWidth: "70px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        "& .MuiChip-label": {
                                          fontSize: "0.75rem",
                                          padding: "0 8px",
                                          lineHeight: 1,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                          paddingTop: "1px"
                                        }
                                      }}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })}

                        </tbody>
                      </table></> : ''

                )}
              </>
          }
        </div>
      </div>
    </Layout>
  );
};

export default SchemeInvestor;