import { LineChart, Line, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import ChartCard from '../../components/dashboard/ChartCard';
import { useEffect, useState } from 'react';
import { convertNotation, convertToUsd } from '../../utils/StringUtil';

const Investor = ({ currency: parentCurrency, numInvList, uniqueInvList, activeInvList, inrAgainstUsd }) => {

  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (uniqueInvList.length > 0) {
      setIsAnimated(true);
    }
  }, [uniqueInvList]);

  return (
    <div className="container-fluid py-4">
      <div className="row ">
      <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard title="Number of Investors"
            chartInfo={[
              { name: "No of Investors", color: "#602f6b" },
              { name: "No of Active Users", color: "#b19cd9" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={numInvList}>
                <XAxis
                  dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }}
                  padding={{ right: 1 }}
                  strokeWidth={2} />
                <YAxis
                  tick={{ fontSize: 12, fontWeight: 'bold' }}
                  strokeWidth={2}
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="cumulativeInvestors"
                  fill="#602f6b"
                  stroke="#602f6b"
                  name="Number of Investors"
                  dot={{ r: 2 }}
                  strokeWidth={1}
                />
                <Line
                  type="monotone"
                  dataKey="cumulativeActiveInvestors"
                  stroke="#b19cd9"
                  fill="#b19cd9"
                  name="Number of Active Users"
                  dot={{ r: 2 }}
                  strokeWidth={1}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
      <div className="row ">
      <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard
            title="New Active Investors"
            chartInfo={[
              { name: "MOM Active Investors", color: "#e6a8d7" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={activeInvList}>
                <XAxis
                  dataKey="month"
                  tick={{ fontSize: 10, fontWeight: 'bold' }}
                  strokeWidth={2} />
                <YAxis
                  tick={{ fontSize: 12, fontWeight: 'bold' }}
                  strokeWidth={1}
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                <Tooltip />
                <Bar dataKey="momActiveInvestor"  barSize={40} stroke="#e6a8d7" fill="#e6a8d7" name="MOM Active Investors" />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
      <div className="row">
      <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard
            title="Monthly Active Investors"
            chartInfo={[
              { name: "MOM Unique Active Investors", color: "#dda0dd" },
              { name: "MOM Transactions", color: "#800080" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={uniqueInvList}>
                <XAxis
                  dataKey="month"
                  tick={{ fontSize: 10, fontWeight: 'bold' }}
                  padding={{ right: 1 }}
                  strokeWidth={2} />
                <YAxis
                  tick={{ fontSize: 12, fontWeight: 'bold' }}
                  strokeWidth={2}
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="momUniqueInvestors"
                  stroke="#dda0dd"
                  fill="#dda0dd"
                  strokeWidth={1}
                  name="MOM Unique Active Investors"
                  dot={{ r: 2 }}
                  isAnimationActive={isAnimated}
                  animationDuration={1500}
                  animationEasing="ease-in-out"
                />
                <Line
                  type="monotone"
                  dataKey="momTransactions"
                  stroke="#800080"
                  fill="#800080"
                  strokeWidth={1}
                  name="MOM Transactions"
                  dot={{ r: 2 }}
                  isAnimationActive={isAnimated}
                  animationDuration={1500}
                  animationEasing="ease-in-out"
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

export default Investor;
