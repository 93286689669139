import React from 'react';
import { toCompactPricePrefix} from '../../../utils/StringUtil';

const CustomAmountTooltip = ({ active, payload, label, parentCurrency}) => {
  if (active && payload && payload.length) {
    
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#ffffff',
          border: '1px solid #cccccc',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{`${label}`}</p>
        {payload.map((item, index) => (
          <p
            key={index}
            style={{
              color: item.color,
              margin: 0,
            }}
          >
            {item.name === "MOM Closed Scheme" ? `${item.name}: ${item.value}` :
            `${item.name}: ${toCompactPricePrefix(item.value, parentCurrency)}`
            }
           
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default CustomAmountTooltip;
